body {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
  margin: 0;
  font-family: sinistreregular;
  font-size: 30px;
  line-height: 1.5;
  position: relative;
  overflow-x: hidden;
}

.index-img {
  background-image: url("background.b38effd5.png");
  background-attachment: fixed;
}

.about-img {
  background-image: url("background.b38effd5.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

h1 {
  margin: 0;
  font-size: 40px;
  line-height: 1;
}

@media (width >= 600px) {
  h1 {
    font-size: 50px;
  }
}

.about-container p, .about-container a, .about-container span {
  text-shadow: 0 0 10px #ffffff4d, 0 0 20px #000000d6;
  filter: drop-shadow(1px 2px 25px #000000d6);
  margin-top: 46px;
  margin-bottom: 46px;
  font-size: 22px;
}

@media (width >= 600px) {
  p, a, span {
    font-size: 30px;
  }
}

p i {
  color: #e6d7ff;
  letter-spacing: -1.222px;
}

.portal {
  z-index: 2;
  max-width: 770px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  padding-top: 18vh;
  position: relative;
}

@media (width >= 600px) {
  .portal {
    padding-top: 1vh;
  }
}

.about-container {
  color: #fff;
  z-index: 1;
  max-width: 900px;
  margin: 0 auto;
  padding: 10vh 20px;
  position: relative;
}

body:before {
  content: "";
  opacity: .1111;
  z-index: 1;
  background-color: #003750;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.link {
  text-align: center;
  width: 100%;
  display: block;
}

.banner {
  width: 100%;
  margin-bottom: 2rem;
}

.banner img {
  width: 100%;
  height: auto;
  display: block;
}

.about-logo {
  max-width: 280px;
  margin: 0 auto 2rem;
  display: block;
}

@media (width >= 600px) {
  .about-logo {
    max-width: 300px;
  }
}

a {
  color: #e6d7ff;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

object {
  animation: .88s ease-out forwards fadeIn;
}

html {
  height: 100%;
  min-height: 100vh;
}

.metaInfoItem {
  margin-top: 12px;
}

.metaInfo div, .metaInfo a {
  font-size: 18px;
}

@media (width >= 600px) {
  .metaInfo div, .metaInfo a {
    font-size: 22px;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.rotate-portal {
  transform-origin: center;
  animation: 1s linear infinite rotate;
}
/*# sourceMappingURL=index.0a861bef.css.map */
