body {
  margin: 0;
  font-family: sinistreregular;
  font-size: 30px;
  line-height: 1.5;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 100vh;
  width: 100%;
  position: relative;
  overflow-x: hidden;
}

.index-img {
  background-attachment: fixed;
  background-image: url("./img/background.png");
}

.about-img {
  position: fixed; /* Fixes the background to the viewport */
  top: 0;
  left: 0;
  width: 100vw; /* Ensures full width */
  height: 100vh; /* Ensures full height */
  background-size: cover; /* Scales to cover entire viewport */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents tiling */
  background-image: url("./img/background.png");
}

h1 {
  margin: 0;
  line-height: 1;
  font-size: 40px;
}

@media (min-width: 600px) {
  h1 {
    font-size: 50px;
  }
}


.about-container p,
.about-container a,
.about-container span {
  margin-top: 46px;
  margin-bottom: 46px;
  font-size: 22px;
  text-shadow: 0 0 10px #ffffff4d, 0 0 20px #000000d6;
  filter: drop-shadow(1px 2px 25px #000000d6);

}

@media (min-width: 600px) {
  p,
  a,
  span {
    font-size: 30px;
  }
}

p i {
  color: #e6d7ff;
  letter-spacing: -1.222px;
}

.portal {
  max-width: 770px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 2;
  padding-top: 18vh;
}

@media (min-width: 600px) {
  .portal {
    padding-top: 1vh;
  }
}

.about-container {
  max-width: 900px;
  margin: 0 auto;
  padding-top: 10vh;
  padding-bottom: 10vh;
  padding-left: 20px;
  padding-right: 20px;
  color: white;
  position: relative;
  z-index: 1; /* Ensures content sits above the background */
}

/* New overlay */
/* Modify the overlay to also be fixed */
body::before {
  content: "";
  position: fixed;
  /* Change from absolute to fixed */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #003750;
  opacity: 0.1111;
  z-index: 1;
}

.link {
  text-align: center;
  display: block;
  width: 100%;
}

.banner {
  width: 100%;
  margin-bottom: 2rem;
}

.banner img {
  width: 100%;
  height: auto;
  display: block;
}

.about-logo {
  max-width: 280px;
  margin: 0 auto 2rem;
  display: block;
}

@media (min-width: 600px) {
  .about-logo {
    max-width: 300px;
  }
}

a {
  color: #e6d7ff;
}

/* Add keyframes for fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Apply animation to all elements */
object {
  animation: fadeIn 0.88s ease-out forwards;
}

/* Add this new rule */
html {
  height: 100%;
  min-height: 100vh;
}

.metaInfoItem {
  margin-top: 12px;
}

.metaInfo div,
.metaInfo a {
  font-size: 18px;
}

@media (min-width: 600px) {
  .metaInfo div,
  .metaInfo a {
    font-size: 22px;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.rotate-portal {
  animation: rotate 1s linear infinite;
  transform-origin: center;
}
